<template>
  <div class="row">
    <div class="col-lg-12 d-flex justify-content-end mb-h" v-if="isNotWizard">
      <div class="col-lg-6 p-0">
        <div  class="float-right">
          <WButton info
            v-if="!isDisable"
            @click="openHotspotWizard"
          >
            {{ $t('hotspot.addHS20') }}
          </WButton>
        </div>
      </div>
    </div>
    <div class="col-lg-12" v-if="isNotWizard">
      <div class="row">
        <div class=" col-lg-12 ">
          <div class="row mb-1 mt-0">
            <div class="col-lg-12">
              <div class="row w-100 justify-content-start ml-0">
<!--                <div class="filters__multiple">-->
<!--                  <div class="input-group input-group-sm filters__input">-->
<!--                  <span class="input-group-addon filters__field-icon"><i-->
<!--                    class="fa fa-map-marker"></i></span>-->
<!--                    <select @change="refreshHotspotsList"-->
<!--                            class="form-control form-control-sm filters__field"-->
<!--                            v-model="filters.location">-->
<!--                      <option :selected="true" :value="'All locations'">{{-->
<!--                        $t('general.allLocations')-->
<!--                        }}-->
<!--                      </option>-->
<!--                      <option v-for="location in locationsList" :value="location.id"-->
<!--                              :key="location.id">-->
<!--                        {{ location.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
                <div
                  v-if="false"
                  class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">
<!--                  <div-->
<!--                    class="  d-flex align-items-end justify-content-start"-->
<!--                  >-->
<!--                    <Switch-component-->
<!--                      :disabled="this.filters.location === 'All locations'"-->
<!--                      v-model="filters.with_childs"-->
<!--                      class="switcher-with-childs-for-use-in-filters"-->
<!--                      @change="refreshHotspotsList"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--                    <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--                  </div>-->

                </div>
                            <div class="col-lg-3 p-0 pr-1" >
            <div class=" ">
              <div class=" ">
                <div class="input-group input-group-sm ">
                  <span class="input-group-addon"><i class="fa fa-search"></i></span>
                  <input
                    id="search"
                    name="search"
                    :placeholder="$t('general.searchPlaceHolder')"
                    class="form-control"
                    v-model="filters.query"
                  />
                  <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">
                    <i class="fa fa-remove"></i>
                  </span>
                </div>
              </div>
            </div>
            </div>
                <div class="col-lg-3  p-0 " v-if="areLocationsVisible">
                                                                                                            <div
                  class="input-group-for-location-select-in-hotspot--filters   ">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-hotspot--filters"
                    enable-max-option-width
                    small-size
                     :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshHotspotsList"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                </div>
                </div>
<!--              <div class="col-lg-6 p-0">-->
<!--                                              <div  class="float-right">-->
<!--                                <button class="btn btn-success btn-md float-right" @click="openHotspotWizard" v-if="!isDisable">-->
<!--                {{ $t('hotspot.addHS20') }}-->
<!--              </button>-->
<!--                </div>-->
<!--              </div>-->
              </div>
            <div class="row w-100 justify-content-start mt-q">
              <div v-if="isFiltersActive" class="mb-1 ml-1">
                <WButton secondary outline sm
                  customClass="new-modern-style-btn-in-filters"
                  @click="resetAllFilters"
                >
                  <i class="fa fa-close"></i>
                  {{ $t('general.resetAllFilters') }}
                </WButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div class="col-lg-12" v-if="isNotWizard">
      <section class="hotspots" :class="{ 'edit-visible': editVisible }">
        <section class="hotspots-list animated fadeIn">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <!--                <div class="loader loader-backdrop loader-backdrop-hotspot" v-if="isHotspotsLoading"></div>-->
                <div v-if="isHotspotsLoading"><Loader-spinner></Loader-spinner></div>
                <div class="card-header">
                  <div>
                    <i class="fa fa-align-justify"></i>
                    {{ $t('hotspot.hs20profiles') }}
                  </div>
                </div>
                <div class="card-block">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ $t('general.name') }}</th>
                        <th v-if="!editVisible">{{ $t('hotspot.domains') }}</th>
                        <th v-if="!editVisible">{{ $t('hotspot.roaming') }}</th>
                        <th v-if="!editVisible">{{ $t('hotspot.realm') }}</th>
                        <th v-if="!editVisible">{{ $t('hotspot.cellular') }}</th>
                        <th v-if="!editVisible && areLocationsVisible">{{ $t('general.location') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(hotspot, index) in filterItems(hotspotsList)"
                        :key="hotspot.id"
                        :data-index="index"
                        :data-id="hotspot.id"
                        class="table-row"
                        @click="toggleEdit"
                        :class="{ selected: hotspot.id === editVisible, 'hotspot-loading': hotspotSpinner(hotspot.id) }"
                      >
                        <td class="responsive-row">{{ hotspot.name }}</td>
                        <td v-if="!editVisible">
                          <span
                            v-if="!hotspot.domains || !hotspot.domains.length"
                            class="badge badge-light mr-q badge-default"
                          >
                            0
                          </span>
                          <span
                            v-else
                            class="badge badge-light mr-q badge-success"
                            v-tooltip.top-center="{ content: hotspot.domains }"
                          >
                            {{ hotspot.domains.length }}
                          </span>
                        </td>
                        <td v-if="!editVisible">
                          <span
                            v-if="!hotspot.consortiums || !hotspot.consortiums.length"
                            class="badge badge-light mr-q badge-default"
                          >
                            0
                          </span>
                          <span v-else class="badge badge-light mr-q badge-success">
                            {{ hotspot.consortiums.length }}
                          </span>
                        </td>
                        <td v-if="!editVisible">
                          <span
                            v-if="!hotspot.realms || !hotspot.realms.length"
                            class="badge badge-light mr-q badge-default"
                          >
                            0
                          </span>
                          <span v-else class="badge badge-light mr-q badge-success">{{ hotspot.realms.length }}</span>
                        </td>
                        <td v-if="!editVisible">
                          <span
                            v-if="!hotspot.cellular || !hotspot.cellular.length"
                            class="badge badge-light mr-q badge-default"
                          >
                            0
                          </span>
                          <span v-else class="badge badge-light mr-q badge-success">{{ hotspot.cellular.length }}</span>
                        </td>
                        <td class="responsive-row" v-if="!editVisible && areLocationsVisible">
                          {{ commonService.showLocationFromId(hotspot.base_location) }}
                        </td>
                      </tr>
                      <tr v-if="!hotspotsList.length">
                        <td colspan="6" class="no-data-row">
                          <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="hotspot-edit" v-if="editVisible">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="edit-header">
                        <div class="edit-header__info">
                          <div class="edit-header__name">
                            <span class="h4">{{ hotspotEditCurrent.name }}</span>
                          </div>
                          <div class="edit-header__tags"></div>
                          <WButton secondary outline sm
                            customClass="edit-btn-close new-modern-style-btn-close-panel"
                            @click="disableEditMode"
                          >
                            <i class="fa fa-close"></i>
                          </WButton>
                        </div>
                        <div class="edit-header__buttons"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-block pt-0">
                  <small class="text-muted" v-if="!isDisable">{{ $t('general.editingMode') }}</small>
                  <small class="text-muted" v-if="isDisable">{{ $t('general.readOnlyMode') }}</small>

                  <div class="form-group mt-h">
                    <label for="name">{{ $t('general.name') }}</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      :placeholder="$t('hotspot.wizardNamePlaceholder')"
                      class="form-control"
                      :disabled="isDisable"
                      v-validate="'required|max:32'"
                      :class="{ input: true, 'is-danger': errors.has('name') }"
                      v-model="updatedHotspot.name"
                    />
                    <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
                  </div>

                  <div class="form-group mt-h">
                    <label for="hessid">{{ $t('hotspot.hessid') }}</label>
                    <input
                      type="text"
                      name="hessid"
                      id="hessid"
                      placeholder="A3:E5:21:DA:FC:3F"
                      class="form-control"
                      v-validate="'mac'"
                      :disabled="isDisable"
                      :class="{ input: true, 'is-danger': errors.has('hessid') }"
                      v-model="updatedHotspot.hessid"
                    />
                    <span v-show="errors.has('hessid')" class="help is-danger">{{ errors.first('hessid') }}</span>
                  </div>

                  <div class="form-group" v-if="areLocationsVisible">
                    <label for="location">{{ $t('general.location') }}</label>
                    <select
                      v-model="updatedHotspot.base_location"
                      name="location"
                      id="location"
                      class="form-control"
                      :class="{ 'select-disabled': isDisable }"
                      :disabled="isDisable"
                    >
                      <option v-for="location in locationsList" :value="location.id" :key="location.id">
                        {{ location.name }}
                      </option>
                    </select>
                  </div>

                  <Network
                    :hotspotData="updatedHotspot"
                    :isDisable="isDisable"
                    :hotspotService="hotspotLists"
                    :commonService="commonService"
                  ></Network>
                  <Realm
                    :hotspotData="updatedHotspot"
                    :isDisable="isDisable"
                    :hotspotService="hotspotLists"
                    :commonService="commonService"
                  ></Realm>
                  <advancedList
                    :hotspotData="updatedHotspot"
                    :isDisable="isDisable"
                    :hotspotService="hotspotLists"
                    :commonService="commonService"
                  ></advancedList>
                </div>

                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <WButton success outline sm
                      customClass="mr-1"
                      v-if="!isDisable"
                      @click="updateHotspot"
                      :disabled="!updatedHotspot.name"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                  </div>
                  <div>
                    <WButton danger outline
                      customClass="mr-1"
                      v-if="!isDisable"
                      @click="openDeletingDialog(updatedHotspot.id)"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.delete') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                    <WButton secondary outline
                      :customClass="{ invisible: muteEdit }"
                      @click="disableEditMode"
                    >
                      <span v-if="isDisable">{{ $t('general.close') }}</span>
                      <span v-else>{{ $t('general.cancel') }}</span>
                    </WButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>

    <div class="col-lg-12"><router-view></router-view></div>

    <modal title="Delete Redirect" v-model="deletingHotspotModal" class="modal-danger" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">Delete Redirect Rule</h4>
      Confirm deleting
      <b>{{ commonService.hotspotNamebyId(hotspotIdToDelete) }}</b>
      ?
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelDeleting"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteRedirect"
        >
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </modal>
  </div>
</template>

<script>
import commonService from '../../services/commonService';
import hotspotService from '../../services/hotspotService';
import hotspotPollingService from '../../services/hotspotPollingService';
import Modal from '../../components/Modal.vue';
import Network from '../../components/Hotspots/Network.vue';
import Realm from '../../components/Hotspots/Realm.vue';
import advancedList from '../../components/Hotspots/advancedList.vue';
import helpers from '../../helpers';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import locationService from '../../services/locationService';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import listsForHotspotMixin from './mixins/listsForHotspotMixin';

import { intervalUpdateModeMixin } from '@/mixins';

export default {
  name: 'Hotspot',
  components: {
    Modal,
    Network,
    Realm,
    advancedList,
    SwitchComponent,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors
  },
  mixins: [intervalUpdateModeMixin, listsForHotspotMixin],
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      updatedHotspot: {},
      hotspotIdToDelete: '',
      deletingHotspotModal: false,
      filters: {
        location: 'All locations',
        with_childs: false,
        query: ''
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isNotWizard() {
      return this.$route.name === 'Hotspot';
    },
    editVisible() {
      return this.$store.state.editHotspotId;
    },
    muteEdit() {
      return this.$store.state.muteHotspotEdit;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    isFiltersActive() {
      return (
        this.filters.location !== 'All locations' ||
        this.filters.query !== ''
      );
    },
    hotspotsList() {
      return this.$store.state.hotspotsList;
    },
    commonService() {
      return commonService;
    },
    hotspotService() {
      return hotspotService;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    // isHotspotsLoading() {
    //   return this.$store.state.loadingRedirects;
    // },
    isHotspotsLoading() {
      return this.$store.state.loadingHotspots;
    },
    hotspotEditCurrent() {
      const { hotspotsList } = this.$store.state;
      let currentHotspot;
      hotspotsList.forEach((hotspot) => {
        if (hotspot.id == this.$store.state.editHotspotId) {
          currentHotspot = JSON.parse(JSON.stringify(hotspot));
        }
      });

      this.updatedHotspot = JSON.parse(JSON.stringify(currentHotspot));

      // if (!this.updatedHotspot.cellular) {
      //     this.updatedHotspot.cellular = [];
      // }
      // if (!this.updatedHotspot.venue.names) {
      //     this.updatedHotspot.venue.names = [];
      // }
      // if (!this.updatedHotspot.domains) {
      //     this.updatedHotspot.domains = [];
      // }
      // if (!this.updatedHotspot.realms) {
      //     this.updatedHotspot.realms = [];
      // }
      // if (!this.updatedHotspot.consortiums) {
      //     this.updatedHotspot.consortiums = [];
      // }

      return currentHotspot;
    }
  },
  watch: {
    hotspotsList() {
      hotspotPollingService.startPolling(this);
    }
  },
  methods: {
    toggleEdit(e) {
      if (e.target.dataset.action === 'delete') {
        return;
      }
      const selectedNode = e.target.closest('[data-id]');
      const { id } = selectedNode.dataset;
      this.$store.commit('setHotspotEditId', id);
    },
    deleteRedirect() {
      hotspotService.deleteHotspot(this);
    },
    openHotspotWizard() {
      this.$router.push({ name: 'hotspot-wizard' });
      this.disableEditMode();
      this.$store.commit('clearNewHotspotData');
    },
    disableEditMode() {
      this.$store.commit('setHotspotEditId', null);
      this.hotspotIdToDelete = '';
    },
    refreshHotspotsList() {
      hotspotService.getHotspots(this, true);
    },
    filterItems(items) {
      let filtered = items;

      filtered = this.hotspotSearchQuery(filtered, this.filters.query);
      return filtered;
    },
    hotspotSearchQuery (hotspotArray, searchQuery){
    if (!hotspotArray) {
      return [];
    }
    return hotspotArray.filter(
      (item) => item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
    );
  },
    // resetAllFilters() {
    //   this.disableEditMode();
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     query: '',
    //     location: 'All locations',
    //     with_childs: false
    //   };
    //   this.refreshHotspotsList();
    // },
    resetAllFilters() {
      this.disableEditMode();
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        query: '',
        // location: 'All locations',
        // with_childs: false
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.refreshHotspotsList();
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    updateHotspot() {
      hotspotService.updateHotspot(this);
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshHotspotsList()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    openDeletingDialog(id) {
      this.hotspotIdToDelete = id;
      this.deletingHotspotModal = true;
    },
    cancelDeleting() {
      this.deletingHotspotModal = false;
      this.hotspotIdToDelete = '';
    },
    hotspotSpinner(forceId) {
      const { hotspotOperations } = this.$store.state;
      const hotspotRows = document.querySelectorAll('tr');

      hotspotRows.forEach((hotspotRow) => {
        hotspotRow.classList.remove('hotspot-loading');
      });
      if (forceId) {
        hotspotOperations.forEach((operation) => {
          if (operation.model_id === forceId) {
            setTimeout(() => {
              const hotspotRow = document.querySelector(`[data-id="${forceId}"]`);
              if (hotspotRow) {
                hotspotRow.classList.add('hotspot-loading');
              }
            }, 1);
          }
        });
      } else if (hotspotOperations.length === 0) {
      } else {
        hotspotOperations.forEach((operation) => {
          hotspotOperations.forEach((hotspotRow) => {
            if (hotspotRow.dataset.id === operation.model_id) {
              hotspotRow.classList.add('hotspot-loading');
            }
          });
        });
      }
    }
  },
  // created() {
  //   hotspotService.getHotspots(this);
  //   this.disableEditMode();
  // }
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    hotspotService.getHotspots(this, true);
    this.disableEditMode();
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      hotspotService.getHotspots(this, true);
    });
  }
};
</script>

<style lang="scss">
        .input-group-for-location-select-in-hotspot--filters {

    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-hotspot--filters  .select__list-container {
    width: 400px;
  }
.hotspots {
  display: flex;
}

.hotspots-list,
.hotspot-edit {
  transition: width 0.2s;
}

.hotspots-list {
  width: 100%;
}

.hotspot-edit {
  display: inline-block;
  width: 0%;
}

.hotspot-edit .card-header {
  border: 0;
}

.edit-visible .hotspots-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 35%;
}

.edit-visible .hotspot-edit {
  transition: width 0.2s;
  width: 65%;
  min-width: 500px;
}

.hotspot-loading {
  animation-name: blink;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.url-input-double {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;

  padding-right: 15px;
  padding-left: 15px;

  & .form-group {
    flex-grow: 1;
  }
}
</style>

<style lang="scss">
.loader-backdrop-hotspot:after {
  position: absolute;
  top: 30px;
}
</style>
